/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { go } from 'react-router-redux';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

import { ApiEndPoints } from './constant';

export const getAllCoupon = createAsyncThunk(
	'getAllCoupon',
	async (
		{ page, couponType, statusFilter, q, sortBy, partnerFilter, perPage }: any,
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.get('admin/coupons', {
				params: {
					page,
					couponType,
					status: statusFilter,
					q,
					sortBy,
					partnerFilter,
					include: 'category,partner,brand,country,views,translation',
					perPage,
				},
				headers: {
					'x-locale': 'en',
				},
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const AddCoupon = createAsyncThunk(
	'AddCoupon',
	async (backendObject: any, thunkAPI) => {
		try {
			const response = await axiosInstance.post(`admin/coupons`, backendObject);
			if (response.status === 200) {
				toast.success('Coupon added successfully');
				thunkAPI.dispatch(go(-1));
				return response.data;
			}
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const UpdateCoupon = createAsyncThunk(
	'UpdateCoupon',
	async (
		{ backendObject, id }: { backendObject: any; id: string },
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.put(
				`admin/coupons/${id}`,
				backendObject
			);
			if (response.status === 204) {
				toast.success('Coupon Updated successfully');
				thunkAPI.dispatch(go(-1));
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const DeleteCoupon = createAsyncThunk(
	'DeleteCoupon',
	async (
		{
			id,
			sendStatus,
			tab,
			statusFilter,
			query,
			partnerFilter,
		}: {
			id: string;
			sendStatus: number;
			partnerFilter?: number;
			tab?: number;
			statusFilter?: number;
			query?: string;
		},
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.patch(`admin/coupons/${id}`, {
				status: sendStatus,
			});
			if (response.status === 204) {
				toast.success(
					sendStatus
						? 'Coupon status changed to Active'
						: 'Coupon status changed to Inactive'
				);
				const { coupon }: any = thunkAPI.getState();
				const page = coupon.pagination.currentPage;
				thunkAPI.dispatch(
					getAllCoupon({
						page,
						isRefresh: true,
						statusFilter,
						couponType: tab,
						q: query,
						partnerFilter,
					})
				);
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const getCouponsById = createAsyncThunk(
	'getCouponsById',
	async (id: string, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/coupons/${id}`, {
				params: {
					include: 'category,partner,brand,country,views,translation',
				},
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const searchCoupon = createAsyncThunk(
	'searchCoupon',
	async ({ q, couponType, status, sortBy, partner }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get(
				`admin/coupons?q=${q}&couponType=${couponType}&status=${status}&sortBy=${sortBy}&partner=${partner}`,
				{
					params: {
						include: 'category,partner,brand,country,views,translation',
					},
				}
			);
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);
export const getCouponsForCashback = createAsyncThunk(
	'getCouponsForCashback',
	async ({ brands, countries, couponCode }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/coupons`, {
				params: {
					brands,
					countries,
					couponCode,
					status: 1,
					couponType: 1,
				},
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);
export const getConsumerPartnerList = async () => {
	try {
		const response = await axiosInstance.get(ApiEndPoints.adminConsumerPartners);
		return response.data.data;
	} catch (e: any) {
		toast.error(getGenericErrorMessage(e));
		return [];
	}
};
